<script setup lang="ts">
import type { UseFetchOptions } from '#app'

const props = defineProps({
  embed: {
    required: true,
  },
  width: {
    required: false,
    default: 280,
  },
  height: {
    required: false,
    default: 210,
  },
  classNames: {
    required: false,
    default: '',
  },
  imageClassNames: {
    required: false,
    default: '',
  },
  aspectRatio: {
    required: false,
    default: 'aspect-video',
  },
  sizes: {
    required: false,
    default: 'sm:270px lg:300px xl:278px',
  },
  lazy: {
    required: false,
    default: true,
  },
})
const emit = defineEmits(['loaded', 'imageFetched'])
const skeleton = ref(null)
const thumbnail = ref(null)
const imageLoaded = ref(false)

const { data: embedData, pending } = await useFetch(
  `/api/unfurl?url=${props.embed}`,
  {
    pick: ['body'],
  } as UseFetchOptions<any>,
)

const image = computed(() => {
  if (
    embedData.value
    && embedData.value.body
    && embedData.value.body.open_graph
    && embedData.value.body.open_graph.images
  )
    return embedData.value.body.open_graph.images[0].url
  else
    return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/310px-Placeholder_view_vector.svg.png'
})

const altText = computed(() => {
  if (
    embedData.value
    && embedData.value.body
    && embedData.value.body.open_graph
    && embedData.value.body.open_graph.title
  )
    return embedData.value.body.open_graph.title
  else return 'Placeholder'
})

// Emit the image URL after fetch
if (embedData.value)
  emit('imageFetched', image.value)

function emitLoaded() {
  emit('loaded', image.value)

  imageLoaded.value = true
}

// emitLoaded() if embedData.body.open_graph is undefined

// console.log(embedData.value.body.open_graph.images[0].url)
</script>

<template>
  <div :class="props.classNames" class="relative">
    <transition name="fade">
      <NuxtPicture
        ref="thumbnail"
        :src="image"
        :width="props.width"
        :height="props.height"
        class="object-cover opacity-0 transition duration-500 ease-in-out"
        :sizes="props.sizes"
        densities="x1 x2"
        :loading="props.lazy ? 'lazy' : 'eager'"
        :alt="altText"
        :class="[aspectRatio, { 'opacity-100': imageLoaded }]"
        :img-attrs="{ class: imageClassNames }"
        fit="cover"
        format="avif"
        placeholder
        @load="emitLoaded"
      />
    </transition>
  </div>
</template>
