export function useUtils() {
  /**
   * Limit string length and add ellipsis
   * @param text
   * @param maxLength
   */
  const limitStrLength = (text: string, maxLength: number): string => {
    const cleanedText = text.replace(/(<([^>]+)>)/g, '')
    return cleanedText.length > maxLength - 3
      ? `${cleanedText.substring(0, maxLength).trimEnd()}...`
      : cleanedText
  }

  /**
   * Sort items by label
   * @param items
   */
  const sortItems = (items: { label: string }[]): { label: string }[] => {
    return items.sort((a, b) => a.label.localeCompare(b.label))
  }

  /**
   * Logger
   */

  const log = (name: string) => {
    console.info(name)
  }

  return { limitStrLength, sortItems, log }
}
